<template>
  <StatusPage>
    <template #icon><IconCheckBrand /></template>
    <template #title>E-mail подтвержден</template>
    <template #desc>Ваш e-mail успешно подтвержден. Теперь аккаунт отправлен на проверку модератору. Проверка длится в течение 1-5ч.</template>
    <template #actions><BaseButton :to="{name: 'auth-login'}" color-primary>Авторизоваться</BaseButton></template>
  </StatusPage>
</template>

<script setup>
import StatusPage from '@/components/layouts/StatusPage'
import IconCheckBrand from '@/components/icons/IconCheckBrand'
import BaseButton from '@/components/common/BaseButton'

useHead({
  title: 'E-mail подтвержден'
})
</script>
